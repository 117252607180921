import React, { useEffect, useState } from 'react';
import { Button, Message } from 'semantic-ui-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Empty from 'components/common/Empty';
import Loader from 'components/common/PageLoader';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import Toolbar from 'components/common/Toolbar';
import SidebarFilter from 'components/common/SidebarFilter';
import { formatSearchParameter } from 'helpers/formatUrlParameter';
import getAllBills from 'redux/actions/settings/getAllBills';
import { canPerformAction } from 'helpers/permissions';
import permissions from 'constants/permissions';
import getUserRoles from 'helpers/getUserRoles';
import BillingList from 'components/Settings/Billing/BillingList';
import BillRequests from './BillRequests';

const Billing = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { search } = useLocation();
  const [filterOn, setFilterOn] = useState(false);
  const [filterForm, setFilterForm] = useState({});
  const urlParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlParams);

  const { tab, option } = useParams();

  const { loading, error, data: bills } = useSelector(
    ({ settings: { allBills } }) => allBills,
  );

  useEffect(() => {
    getAllBills()(dispatch);
  }, []);

  useEffect(() => {
    if (!isEmpty(filterForm) && search) return setFilterForm(params);
  }, [dispatch, filterForm]);

  const { currentOrgId, roles, orgUsers } = getUserRoles();

  const handleFilter = (e, { name, value }) => {
    push(
      formatSearchParameter({
        ...filterForm,
        [name]: value,
      }),
    );
    setFilterForm({ ...filterForm, [name]: value });
  };

  const onQuery = queries => {
    getAllBills(queries)(dispatch);
  };

  const rightComponent = (
    <>
      <ToolbarFooter
        filterClicked={() => {
          setFilterOn(true);
        }}
        filter
        onSearch={onQuery}
      />
      {canPerformAction(
        permissions.canPerformAction.canAddBill,
        roles,
        { orgUsers, currentOrgId },
      ) && (
        <>
          <Button onClick={() => push('/settings/billing/request')}>
            New Bill Requests
          </Button>
          <Button
            primary
            onClick={() => push('/settings/billing/add')}
          >
            Add bill
          </Button>
        </>
      )}
    </>
  );
  return (
    <>
      {!option && (
        <>
          <Toolbar
            header={{
              title: 'Bills',
              navigation: false,
              rightComponent,
            }}
            style={{
              marginLeft: '0px',
            }}
          />
          <div className="billings">
            {loading ? (
              <Loader />
            ) : error ? (
              <Message negative>{error.message}</Message>
            ) : bills.length === 0 ? (
              <Empty message="No billings found" />
            ) : (
              <BillingList bills={bills} />
            )}
          </div>
          <SidebarFilter
            filterOn={filterOn}
            setFilterOn={setFilterOn}
            form={filterForm}
            setForm={setFilterForm}
            handleChange={handleFilter}
            activity="billings"
          />
        </>
      )}

      {tab === 'billing' && option === 'request' && <BillRequests />}
    </>
  );
};

export default Billing;
