/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  Message,
  Checkbox,
  Button,
  Icon,
  Form,
  TextArea,
  Popup,
} from 'semantic-ui-react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import phoneUtils from '@exuus/rwanda-phone-utils';
import Toolbar from 'components/common/Toolbar';
import getOrganizationMembers from 'redux/actions/organizations/organizationMembers';
import Loader from 'components/common/PageLoader';
import Thumbnail from 'components/common/Thumbnail';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import './index.scss';
import { isAdmin, isSuperAdmin } from 'helpers/checkRoles';
import Empty from 'components/common/Empty';
import capitalize from 'utils/capitalize';
import DeleteConfirmationModel from 'components/common/modals/SimpleModal';
import { deleteRemoveMultipleMembers } from 'redux/actions/organizations/deleteGroupMember';

import isMemberBalanceEmpty from 'helpers/isMemberBalanceEmpty';
import formatNumber from 'helpers/formatNumber';

const OrganizationMembers = props => {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const [form, setForm] = useState({});
  const [confirmMemberModel, setConfirmMemberModel] = useState({
    action: '',
    header: '',
    body: '',
    context: '',
    model: false,
    modelForm: false,
  });
  const {
    match: {
      params: { projectId, organizationId, tabOption, option },
    },
    history: { push },
  } = props;

  const groupId = tabOption || option;

  const { items, loading, error, meta } = useSelector(
    ({ organizations: { organizationMembers } }) =>
      organizationMembers,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getOrganizationMembers(groupId)(dispatch);
  }, []);

  const { loading: DeleteMembersloading } = useSelector(
    ({ organizations: { deleteMultipleMembers } }) =>
      deleteMultipleMembers,
  );

  const { roles = [] } = useSelector(
    ({ user: { currentUser: { details = {} } = {} } = {} }) =>
      details,
  );

  const onSearch = ({ search }) => {
    const filteredMembers = items?.filter(item =>
      [
        item?.user?.first_name,
        item?.user?.middle_name,
        item?.user?.last_name,
        item?.user?.phone_number,
      ].some(fieldToSearch =>
        fieldToSearch?.toLowerCase()?.includes(search?.toLowerCase()),
      ),
    );
    return setMembers(filteredMembers);
  };

  const onPaginate = queries => {
    getOrganizationMembers(groupId, queries)(dispatch);
  };

  useEffect(() => {
    if (!isEmpty(items)) return setMembers(items);
    getOrganizationMembers(groupId)(dispatch);
  }, [dispatch, items]);

  const groupName = items && items[0] && items[0].group.name;

  const hasOverDueLoans = (
    balance = 0,
    dueDate = moment().format(),
  ) => {
    if (balance <= 0) return false;

    return moment(dueDate).isValid()
      ? !moment().isBefore(dueDate)
      : false;
  };

  const toggleAllMembers = (e, { checked }) => {
    if (items.length > 0) {
      const allMembers = checked
        ? items.map(
            ({
              user_id: id,
              savings_balance: cumulativeSavings,
              social_funds_balance: socialFunds,
            }) => ({
              id,
              balance: !isMemberBalanceEmpty(
                cumulativeSavings,
                socialFunds,
              ),
            }),
          )
        : [];
      setSelectedMembers(allMembers);
    }
  };

  const handleCheckMember = (
    e,
    { value: { id, balance }, checked },
  ) => {
    const memberIds = checked
      ? [...selectedMembers, { id, balance }]
      : selectedMembers.filter(member => member.id !== id);
    setSelectedMembers(memberIds);
  };

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const onCloseConfirmModal = () => {
    setConfirmMemberModel({
      ...confirmMemberModel,
      model: false,
      modelForm: false,
    });
  };

  const handleSumbitMemberActions = () => {
    switch (confirmMemberModel.context) {
      case 'remove/delete-multiple-member':
        deleteRemoveMultipleMembers(
          groupId,
          selectedMembers.map(({ id }) => id),
          form,
        )(confirmMemberModel.action, dispatch, onCloseConfirmModal);
        setSelectedMembers([]);
        break;
      default:
        break;
    }
  };

  const membersSelectedBalanceEmpty = () =>
    selectedMembers.every(member => !member.balance);

  const getUser = user => {
    if (!user) {
      return {
        firstName: '',
        lastName: '',
        phoneNumber: '',
      };
    }

    return {
      firstName: user.first_name,
      lastName: user.last_name,
      phoneNumber: user.phone_number,
    };
  };

  return (
    <>
      <Toolbar
        header={{
          breadcrumbTitle: 'breadcrumb-title',
          breadcrumbParent: `${capitalize(groupName)}`,
          breadcrumbParentLink: projectId
            ? `/projects/${projectId}/organizations/${organizationId}/groups/${groupId}`
            : `/organizations/${organizationId}/groups/${groupId}`,
          breadcrumbActive: 'Members',
          navigation: true,
          leftComponent: isAdmin(roles) &&
            selectedMembers.length > 0 && (
              <>
                <Popup
                  content="Sorry, You can delete only members with 0 Rwf saving Balance"
                  disabled={membersSelectedBalanceEmpty()}
                  trigger={
                    <Button
                      icon
                      labelPosition="right"
                      negative
                      className={
                        !membersSelectedBalanceEmpty() &&
                        'action-prevented'
                      }
                      onClick={() =>
                        membersSelectedBalanceEmpty() &&
                        setConfirmMemberModel({
                          action: 'remove',
                          header: 'Remove Member(s)',
                          body: `Are you sure you want to remove ${
                            selectedMembers.length
                          }
                    member(s) from SG ${capitalize(groupName)} ?`,
                          context: 'remove/delete-multiple-member',
                          modelForm: true,
                        })
                      }
                    >
                      <Icon name="cancel" />
                      Remove
                    </Button>
                  }
                />
                <Popup
                  content="Sorry, You can remove only members with 0 Rwf saving Balance"
                  disabled={membersSelectedBalanceEmpty()}
                  trigger={
                    <Button
                      icon
                      labelPosition="right"
                      negative
                      className={
                        !membersSelectedBalanceEmpty() &&
                        'action-prevented'
                      }
                      onClick={() =>
                        membersSelectedBalanceEmpty() &&
                        setConfirmMemberModel({
                          action: 'delete',
                          header: 'Delete Member(s)',
                          body: `Are you sure you want to delete ${
                            selectedMembers.length
                          }
                     member(s) from SG ${capitalize(groupName)} ?`,
                          context: 'remove/delete-multiple-member',
                          modelForm: true,
                        })
                      }
                    >
                      <Icon name="trash" />
                      Delete
                    </Button>
                  }
                />
              </>
            ),
          rightComponent: (
            <ToolbarFooter
              onSearch={onSearch}
              onPaginate={onPaginate}
              paginationMeta={meta}
              exportCSVData={items}
              exportCSVFileName="Members"
            />
          ),
        }}
      />
      <DeleteConfirmationModel
        contentStyle={{ padding: '20px' }}
        title={confirmMemberModel.header}
        content={confirmMemberModel.body}
        open={confirmMemberModel.modelForm}
        onClose={() => onCloseConfirmModal()}
      >
        <Form
          className="delete-form"
          onSubmit={handleSumbitMemberActions}
          loading={DeleteMembersloading}
        >
          <TextArea
            required
            placeholder="Provide a reason for the deletion"
            className="delete-form__reason"
            name="comment"
            onChange={handleChange}
          />
          <Button
            floated="right"
            type="submit"
            content="Confirm"
            color="red"
          />
        </Form>
      </DeleteConfirmationModel>
      <div className="groups-table scroll-wrapper">
        {loading && isEmpty(items) ? (
          <Loader />
        ) : error ? (
          <Message negative>{error.message}</Message>
        ) : items.length === 0 ? (
          <Empty message="No members found" />
        ) : (
          <Table basic="very" size="large">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width="5">
                  {isSuperAdmin(roles) && (
                    <Checkbox
                      onChange={toggleAllMembers}
                      className="action-check"
                    />
                  )}
                  <span>Member</span>
                </Table.HeaderCell>
                <Table.HeaderCell width="2">Shares</Table.HeaderCell>
                <Table.HeaderCell width="2">
                  Fines (RWF)
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Cumulative Savings (RWF)
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Current Savings (RWF)
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Outstanding Loans (RWF)
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Cumulative Social Fund (RWF)
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {members.map(
                ({
                  user_id: memberId,
                  type,
                  user,
                  shares,
                  savings_total: cumulativeSavings,
                  savings_balance: currentSavings,
                  fines_balance: fines,
                  social_funds_balance: socialFunds,
                  loans_balance: outstandingLoans,
                  social_funds_total: cumulativeSocialFund,
                  loans_due_date: loansDueDate,
                }) => (
                  <Table.Row>
                    <Table.Cell width="5">
                      {isSuperAdmin(roles) && (
                        <Checkbox
                          checked={selectedMembers.some(
                            member => member.id === memberId,
                          )}
                          className="action-check"
                          name={memberId}
                          value={{
                            id: memberId,
                            balance: !isMemberBalanceEmpty(
                              cumulativeSavings,
                              socialFunds,
                            ),
                          }}
                          onChange={handleCheckMember}
                        />
                      )}
                      <div
                        className="group-name member-name"
                        onKeyDown={null}
                        role="button"
                        tabIndex="-1"
                        onClick={() =>
                          push(
                            `${
                              projectId
                                ? `/projects/${projectId}`
                                : ''
                            }/organizations/${organizationId}/groups/${groupId}/members/${memberId}`,
                          )
                        }
                      >
                        <Thumbnail
                          name={getUser(user).firstName}
                          secondName={getUser(user).lastName}
                        />
                        <div className="right">
                          <div className="up">
                            <span>{`${getUser(user).firstName} ${
                              getUser(user).lastName
                            }`}</span>
                          </div>
                          {getUser(user).phoneNumber && (
                            <div className="member-bottom-info">
                              <p className="phone">
                                <Icon name="phone" />{' '}
                                {phoneUtils(
                                  getUser(user).phoneNumber,
                                ).format('space')}
                              </p>
                              {type === 'committee' && (
                                <span className="group-status active member">
                                  Committee
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell width="2">
                      {formatNumber(shares)}
                    </Table.Cell>
                    <Table.Cell width="2">
                      {formatNumber(fines)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatNumber(cumulativeSavings)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatNumber(currentSavings)}
                    </Table.Cell>
                    <Table.Cell
                      className={
                        hasOverDueLoans(
                          outstandingLoans,
                          loansDueDate,
                        )
                          ? 'loans'
                          : ''
                      }
                      role="button"
                      tabIndex="-1"
                      onClick={
                        hasOverDueLoans(
                          outstandingLoans,
                          loansDueDate,
                        )
                          ? () =>
                              push(
                                `/organizations/${organizationId}/groups/${groupId}/members/${memberId}/loans`,
                              )
                          : ''
                      }
                      onKeyDown={null}
                    >
                      {formatNumber(outstandingLoans)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatNumber(cumulativeSocialFund)}
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        )}
      </div>
    </>
  );
};

OrganizationMembers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(Object).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default OrganizationMembers;
