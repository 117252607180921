export const validate = ({
  name,
  value,
  form,
  profiles,
  formError,
  min_amount,
  max_amount,
  min_range_exists,
  max_range_exists,
}) => {
  if (name === min_amount || name === max_amount) {
    if (name === max_amount && value <= form?.[min_amount]) {
      return {
        disableBtn: true,
        [max_amount]: {
          ...formError[max_amount],
          status: true,
        },
      };
    }

    if (
      profiles?.some(
        profile =>
          value >= profile?.[min_amount] &&
          value <= profile?.[max_amount],
      )
    ) {
      if (name === min_amount) {
        return {
          disableBtn: true,
          [min_range_exists]: {
            ...formError[min_range_exists],
            status: true,
          },
        };
      }

      if (name === max_amount) {
        return {
          disableBtn: true,
          [max_range_exists]: {
            ...formError[max_range_exists],
            status: true,
          },
        };
      }
    }

    if (
      (name === max_amount && value > form?.[min_amount]) ||
      (name === min_amount && value < form?.[max_amount])
    ) {
      return {
        disableBtn: false,
        [max_amount]: {
          ...formError[max_amount],
          status: false,
        },
      };
    }

    if (
      !profiles?.some(
        profile =>
          value >= profile?.[min_amount] &&
          value <= profile?.[max_amount],
      )
    ) {
      if (name === min_amount) {
        return {
          disableBtn: false,
          [min_range_exists]: {
            ...formError[min_range_exists],
            status: false,
          },
        };
      }

      if (name === max_amount) {
        return {
          disableBtn: false,
          [max_range_exists]: {
            ...formError[max_range_exists],
            status: false,
          },
        };
      }
    }
  }
};

export default ({
  name,
  value,
  form,
  formError,
  profiles,
  setFormError,
  setDisableBtn,
}) => {
  let result;
  switch (name) {
    case 'min_monthly_savings':
      result = validate({
        name,
        value,
        form,
        profiles,
        formError,
        min_amount: 'min_monthly_savings',
        max_amount: 'max_monthly_savings',
        min_range_exists: 'min_savings_range_exists',
        max_range_exists: 'max_savings_range_exists',
      });
      break;
    case 'max_monthly_savings':
      result = validate({
        name,
        value,
        form,
        profiles,
        formError,
        min_amount: 'min_monthly_savings',
        max_amount: 'max_monthly_savings',
        min_range_exists: 'min_savings_range_exists',
        max_range_exists: 'max_savings_range_exists',
      });
      break;
    case 'min_monthly_loans':
      result = validate({
        name,
        value,
        form,
        profiles,
        formError,
        min_amount: 'min_monthly_loans',
        max_amount: 'max_monthly_loans',
        min_range_exists: 'min_loans_range_exists',
        max_range_exists: 'max_loans_range_exists',
      });
      break;
    case 'max_monthly_loans':
      result = validate({
        name,
        value,
        form,
        profiles,
        formError,
        min_amount: 'min_monthly_loans',
        max_amount: 'max_monthly_loans',
        min_range_exists: 'min_loans_range_exists',
        max_range_exists: 'max_loans_range_exists',
      });
      break;
    default:
  }

  setDisableBtn(result.disableBtn);
  setFormError({
    disableBtn: undefined,
    ...formError,
    ...result,
  });
};
