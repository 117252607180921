import initialState from 'redux/initial-states/organizations';
import createOrganization from './createOrganization';
import getSingleOrganization from './getSingleOrganization';
import currentOrganization from './currentOrganization';
import updateSingleOrganization from './updateSingleOrganization';
import listOrganizations from './listOrganizations';
import organizationGroups from './organizationGroups';
import organizationSearch from './organizationSearch';
import singleGroup from './getSingleGroup';
import organizationMembers from './organizationMembers';
import organizationsOverview from './organizationsOverview';
import singleOrganizationOverview from './singleOrganizationOverview';
import fspOrganizationOverview from './fspOrganizationOverview';
import organizationAgents from './organizationAgents';
import groupMember from './getGroupMember';
import inviteAgent from './inviteAgent';
import getAgent from './getAgent';
import bulkCreate from './bulkCreate';
import bulkSms from './bulkSms';
import bulkSmsList from './bulkSmsList';
import deleteGroup from './deleteGroup';
import deleteGroupMember from './deleteGroupMember';
import deleteMultipleMembers from './deleteMultipleMembers';
import changeGroupStatus from './changeGroupStatus';
import searchGroup from './searchGroup';
import agentActivities from './agentActivities';
import updateGroup from './updateGroup';
import getOrgWallet from './getOrgWallet';
import pushNotifications from './pushNotifications';
import getAllPushNotifications from './getAllPushNotifications';

export default (state = initialState, action = {}) => ({
  ...state,
  ...createOrganization(state, action),
  ...getSingleOrganization(state, action),
  ...currentOrganization(state, action),
  ...updateSingleOrganization(state, action),
  ...listOrganizations(state, action),
  ...organizationGroups(state, action),
  ...organizationSearch(state, action),
  ...singleGroup(state, action),
  ...updateGroup(state, action),
  ...organizationMembers(state, action),
  ...organizationsOverview(state, action),
  ...singleOrganizationOverview(state, action),
  ...fspOrganizationOverview(state, action),
  ...organizationAgents(state, action),
  ...groupMember(state, action),
  ...inviteAgent(state, action),
  ...getAgent(state, action),
  ...bulkCreate(state, action),
  ...bulkSms(state, action),
  ...bulkSmsList(state, action),
  ...deleteGroup(state, action),
  ...deleteGroupMember(state, action),
  ...deleteMultipleMembers(state, action),
  ...changeGroupStatus(state, action),
  ...searchGroup(state, action),
  ...agentActivities(state, action),
  ...getOrgWallet(state, action),
  ...pushNotifications(state, action),
  ...getAllPushNotifications(state, action),
});
