/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Message } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import './index.scss';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Toolbar from 'components/common/Toolbar';
import getOrganizationGroups, {
  getAgentGroups,
} from 'redux/actions/organizations/organizationGroups';
import Loader from 'components/common/PageLoader';
import Thumbnail from 'components/common/Thumbnail';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import Empty from 'components/common/Empty';
import { isSuperAdmin } from 'helpers/checkRoles';
import formatNumber from 'helpers/formatNumber';

const OrganizationGroups = props => {
  const {
    organizationId,
    projectId,
    option,
    tabOption,
  } = useParams();

  const { agentGroups } = props;

  const { roles = [] } = useSelector(
    ({ user: { currentUser: { details = {} } = {} } = {} }) =>
      details,
  );

  const agentId = isSuperAdmin(roles) ? tabOption : option;

  const { push } = useHistory();
  const { search, state } = useLocation();
  const currentProjectId = projectId || state?.projectId;

  const { items, loading, error, meta } = useSelector(
    ({ organizations: { organizationGroups } }) => organizationGroups,
  );
  const dispatch = useDispatch();
  const getGroups = (
    organizationId,
    queries = queryString.parse(search),
  ) => {
    return !agentGroups
      ? getOrganizationGroups(
          organizationId,
          currentProjectId,
          queries,
        )(dispatch)
      : agentId &&
          getAgentGroups(organizationId, agentId, queries)(dispatch);
  };

  useEffect(() => {
    getGroups(organizationId);
  }, [dispatch, organizationId, agentId]);

  const onSearch = queries => {
    getGroups(organizationId, queries);
  };

  const onPaginate = queries => {
    getGroups(organizationId, queries);
  };

  return (
    <div className="group__lists">
      <Toolbar
        header={{
          title: 'Saving groups',
          navigation: true,
          rightComponent: (
            <ToolbarFooter
              onSearch={onSearch}
              onPaginate={onPaginate}
              paginationMeta={meta}
              exportCSVData={items}
              exportCSVFileName="groups"
            />
          ),
        }}
      />
      <div className="groups-table">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>{error.message}</Message>
        ) : items.length === 0 ? (
          <Empty message="No groups found" />
        ) : (
          <Table basic="very" size="large">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <span>Saving Groups</span>
                </Table.HeaderCell>
                <Table.HeaderCell>Members</Table.HeaderCell>
                <Table.HeaderCell>
                  Cumulative Savings (RWF)
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Current Savings(RWF)
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Outstanding Loans(RWF)
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Wallet Balance(RWF)
                </Table.HeaderCell>
                <Table.HeaderCell className="sg-status">
                  Status
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {items.map(
                ({
                  id,
                  name,
                  members_count: members,
                  savings_balance: currentSavings,
                  savings_total: cumulativeSavings,
                  loans_balance: outstandingLoan,
                  wallet_balance: walletBalance,
                  status,
                }) => (
                  <Table.Row
                    onKeyDown={null}
                    role="button"
                    tabIndex="-1"
                    onClick={() =>
                      push(
                        `${
                          projectId ? `/projects/${projectId}` : ''
                        }/organizations/${organizationId}/groups/${id}`,
                      )
                    }
                  >
                    <Table.Cell>
                      <div
                        className={`group-name ${status ===
                          'inactive' && 'disabled'}`}
                      >
                        <Thumbnail avatar={null} name={name} />
                        <span>{name}</span>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{members}</Table.Cell>
                    <Table.Cell>
                      {formatNumber(cumulativeSavings)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatNumber(currentSavings)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatNumber(outstandingLoan)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatNumber(walletBalance)}
                    </Table.Cell>
                    <Table.Cell className="status-field sg-status">
                      {status === 'active' ? (
                        <span className="group-status active">
                          Active
                        </span>
                      ) : (
                        <span className="group-status inactive">
                          Inactive
                        </span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        )}
      </div>
    </div>
  );
};

OrganizationGroups.defaultProps = {
  agentGroups: false,
};

OrganizationGroups.propTypes = {
  agentGroups: PropTypes.bool,
};

export default OrganizationGroups;
