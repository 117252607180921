import initialState from 'redux/initial-states/settings';
import addBill from './addBill';
import addFeature from './addFeature';
import deactivateFeature from './deactivateFeature';
import deactivateFeaturesBulk from './deactivateFeaturesBulk';
import getAllBills from './getAllBills';
import getAllFeatures from './getAllFeatures';
import getCategoryFeatures from './getCategoryFeatures';
import getSingleBill from './getSingleBill';
import singleFeature from './singleFeature';
import updateBill from './updateBill';
import updateFeature from './updateFeature';
import addTestFeature from './addTestFeature';
import updateMicroloanSettings from './updateMicroloanSettings';
import getMicroloanSettings from './getMicroloanSettings';
import getMicroloanSettingsData from './getMicroloanSettingsData';

export default (state = initialState, action = {}) => ({
  ...state,
  ...getAllFeatures(state, action),
  ...addFeature(state, action),
  ...updateFeature(state, action),
  ...singleFeature(state, action),
  ...deactivateFeature(state, action),
  ...deactivateFeaturesBulk(state, action),
  ...getCategoryFeatures(state, action),
  ...getAllBills(state, action),
  ...getSingleBill(state, action),
  ...addBill(state, action),
  ...updateBill(state, action),
  ...addTestFeature(state, action),
  ...updateMicroloanSettings(state, action),
  ...getMicroloanSettings(state, action),
  ...getMicroloanSettingsData(state, action),
});
