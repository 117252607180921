import {
  GET_CATEGORY_FEATURES_ERROR,
  GET_CATEGORY_FEATURES_START,
  GET_CATEGORY_FEATURES_SUCCESS,
} from 'constants/action-types/settings/getCategoryFeatures';
import apiAction from 'helpers/apiAction';

export default queries => dispatch =>
  dispatch(
    apiAction({
      url: '/settings/categories',
      method: 'GET',
      queries,
      API_REQUEST_START: GET_CATEGORY_FEATURES_START,
      API_REQUEST_SUCCESS: GET_CATEGORY_FEATURES_SUCCESS,
      API_REQUEST_FAILURE: GET_CATEGORY_FEATURES_ERROR,
      onSuccess: payload => {
        dispatch({
          type: GET_CATEGORY_FEATURES_SUCCESS,
          payload,
        });
      },
      onEnd: () => false,
    }),
  );

export const getAllCategories = ({ source, type }) => dispatch =>
  dispatch(
    apiAction({
      url: `wallets/transactions/types?wallet_type=${source}&&transaction_type=${type}`,
      method: 'GET',
      API_REQUEST_START: GET_CATEGORY_FEATURES_START,
      API_REQUEST_SUCCESS: GET_CATEGORY_FEATURES_SUCCESS,
      API_REQUEST_FAILURE: GET_CATEGORY_FEATURES_ERROR,
      onSuccess: payload => {
        dispatch({
          type: GET_CATEGORY_FEATURES_SUCCESS,
          payload,
        });
      },
      onEnd: () => false,
    }),
  );
