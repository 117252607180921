/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Button, Message, Label, Icon } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, pick, startCase } from 'lodash';
import activityTypes from 'constants/activityTypes';
import { getSingleGroup } from 'redux/actions/organizations/organizationGroups';
import Toolbar from 'components/common/Toolbar';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import getGroupActivitiesAction from 'redux/actions/organizations/getGroupActivities';
import Loader from 'components/common/PageLoader';
import Empty from 'components/common/Empty';
import TYPE_NAMES from 'constants/typeNames';
import SidebarFilter from 'components/common/SidebarFilter';
import { capitalizeEachWords } from 'utils/capitalize';
import ActivityItem from './ActivityItem';
import './index.scss';

const GroupActivities = () => {
  const { search } = useLocation();
  const { push } = useHistory();
  const { organizationId, projectId } = useParams();
  const urlParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlParams);
  const { items, error, meta, loading } = useSelector(
    ({ activities: { groupActivities } }) =>
      groupActivities.getGroupActivities,
  );

  const { item: groupData } = useSelector(
    ({ organizations: { singleGroup } }) => singleGroup,
  );

  const categories = {
    transactions: 'transactions',
    no_transactions: 'no_transactions',
  };
  const [category, setCategory] = useState(
    params.category || categories.transactions,
  );
  const [filterOn, setFilterOn] = useState(false);
  const [form, setForm] = useState({ ...params });

  const { tabOption, option } = useParams();

  const groupId = tabOption || option;

  const dispatch = useDispatch();
  let tabCategory = '';

  const actualAction = type =>
    type === 'credit'
      ? `credited an amount`
      : `has been debited an amount`;

  const getGroupActivities = cycleId => {
    getGroupActivitiesAction(groupId, {
      cycle_id: cycleId,
      ...params,
      ...form,
      category,
    })(dispatch);
  };

  useEffect(() => {
    tabCategory = params.category;
    setCategory(tabCategory || category);
  }, []);

  useEffect(() => {
    if (isEmpty(groupData)) getSingleGroup(groupId)(dispatch);
    if (groupData?.group) {
      getGroupActivities(groupData?.group?.cycle_id);
    }
  }, [dispatch, form, groupData?.group]);

  const title = ({ type, purpose, category }) => {
    if (
      (purpose === 'org_loan' && type === 'credit') ||
      type === 'org_loan' ||
      (type === 'org_loan_request' && category !== 'transactions')
    ) {
      return 'Organization Loan Disbursement';
    }

    if (
      (purpose === 'org_loan' && type === 'debit') ||
      type === 'org_loan' ||
      (type === 'org_loan_request' && category !== 'transactions')
    ) {
      return 'Organization Loan Repayment';
    }

    if (category !== 'transactions') {
      return `${capitalizeEachWords(
        startCase(purpose || type || category),
      )} `;
    }

    return `${capitalizeEachWords(startCase(purpose))}`;
  };

  const handleChangeCategory = value => {
    push({ search: `category=${value}` });

    tabCategory = categories[value];
    setCategory(tabCategory);
    setForm(pick(form, 'category'));
  };

  const handleChange = (e, { name, value }) => {
    if (category === 'transactions') {
      if (name === 'user_id') {
        push({
          search: `?${
            tabCategory || category
              ? `category=${tabCategory || category}&`
              : ''
          }${form.type ? `type=${form.type}&` : ''}${
            form.cycle_id ? `cycle_id=${form.cycle_id}&` : ''
          }user_id=${value}`,
        });
      }
      if (name === 'type') {
        push({
          search: `?${
            tabCategory || category
              ? `category=${tabCategory || category}&`
              : ''
          }${form.user_id ? `user_id=${form.user_id}&` : ''}${
            form.cycle_id ? `cycle_id=${form?.cycle_id}&` : ''
          }type=${value}`,
        });
      }
      if (name === 'cycle_id') {
        push({
          search: `?${
            tabCategory || category
              ? `category=${tabCategory || category}&`
              : ''
          }${form.user_id ? `user_id=${form.user_id}&` : ''}${
            form.type ? `&type=${form.type}&` : ''
          }${value ? `cycle_id=${value}` : ''}`,
        });
      }
    } else {
      if (name === 'search') {
        value = value.split(' ', 1).toString();
        push({
          search: `?${
            tabCategory || category
              ? `category=${tabCategory || category}&`
              : ''
          }search=${value}${
            form.resource ? `&resource=${form.resource}` : ''
          }`,
        });
      }
      if (name === 'resource') {
        push({
          search: `?${
            tabCategory || category
              ? `category=${tabCategory || category}&`
              : ''
          }${
            form.search ? `search=${form?.search}&` : ''
          }resource=${value}`,
        });
      }
    }
    setForm({ ...form, [name]: value });
  };

  const serialize = item =>
    Object.keys(item)
      .map(key => `${key}=${encodeURIComponent(item[key])}`)
      .join('&');

  const removeTag = value => {
    const { category, search, resource } = form;
    const newForm = { search, resource };
    const newItem = Object.keys(newForm)
      .map(e => ({
        [e]: newForm[e],
      }))
      .filter(item => Object.values(item).join() !== value);
    const result =
      newItem.length === 0 ||
      Object.values(...newItem)[0] === undefined
        ? [{}]
        : newItem;
    setForm(...result);
    push({
      search: `?${category ? `category=${category}&` : ''}${serialize(
        ...result,
      )}`,
    });
  };

  const onQuery = queries => {
    const { resource, search } = params;
    getGroupActivitiesAction(groupId, {
      category: tabCategory || category,
      search,
      resource,
      ...queries,
    })(dispatch);
  };
  const types = activityTypes(category);

  return (
    <>
      <Toolbar
        header={{
          breadcrumbParent: 'Activities',
          breadcrumbParentLink: projectId
            ? `/projects/${projectId}/organizations/${organizationId}/groups/${groupId}`
            : `/organizations/${organizationId}/groups/${groupId}`,
          breadcrumbChildren: ` ${groupData?.group?.name || ''}`,
          breadcrumbChildrenLink: projectId
            ? `/projects/${projectId}/organizations/${organizationId}/groups/${groupId}`
            : `/organizations/${organizationId}/groups/${groupId}`,
          meta: (
            <>
              {form.search && (
                <Label as="a">
                  <Icon name="user" />
                  {form.search}
                  <Icon
                    name="delete"
                    onClick={() => removeTag(form.search)}
                  />
                </Label>
              )}
              {form.resource && (
                <Label as="a">
                  <Icon name="clipboard" />
                  {form.resource}
                  <Icon
                    name="delete"
                    onClick={() => removeTag(form.resource)}
                  />
                </Label>
              )}
            </>
          ),
          rightComponent: (
            <>
              <ToolbarFooter
                onPaginate={onQuery}
                paginationMeta={meta}
                onSearch={
                  category === 'transactions' ? false : onQuery
                }
                items={items}
                filterClicked={() => {
                  setFilterOn(true);
                }}
                filter
                exportCSVData={items}
                exportCSVFileName={
                  category === categories.transactions
                    ? `${groupData?.group?.name ||
                        'Group'} Transactions`
                    : `${groupData?.group?.name ||
                        'Group'} Activities`
                }
              />
              <Button
                primary={category === categories.transactions}
                content="Transactions"
                onClick={() =>
                  handleChangeCategory(categories.transactions)
                }
              />
              <Button
                primary={category === categories.no_transactions}
                content="All Activities"
                onClick={() =>
                  handleChangeCategory(categories.no_transactions)
                }
              />

              <SidebarFilter
                {...{
                  filterOn,
                  setFilterOn,
                  form,
                  setForm,
                  filterTransactions: getGroupActivities,
                  handleChange,
                  types,
                  category,
                  typeName: TYPE_NAMES.TYPE_OF_ACTIVITY,
                  searchName: 'Member name',
                  activity: 'group',
                  resourceId: groupId,
                  group: groupData?.group,
                }}
              />
            </>
          ),
        }}
      />
      <div className="group-activities scroll-wrapper">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>
            {error.errors && error.errors[0]
              ? error.errors[0].message.replace(/"/g, '')
              : error.message}
          </Message>
        ) : items.length === 0 ? (
          <Empty message="No group activities" />
        ) : (
          items.map(item => (
            <ActivityItem
              key={item.id}
              title={title(item)}
              user={item.user}
              groupId={groupId}
              description={
                category === categories.transactions
                  ? {
                      ...item,
                      actual_action: actualAction(item.type),
                    }
                  : item
              }
              detailsPopup={item.category === 'group_request'}
            />
          ))
        )}
      </div>
    </>
  );
};

export default GroupActivities;
