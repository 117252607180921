import React, { useRef, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Divider, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Color from 'color';
import LineChart from 'components/common/charts/LineChart';
import './Container.scss';
import formatUrlParameter from 'helpers/formatUrlParameter';
import chevronArrowRight from 'assets/images/chevron-right.svg';
import OverviewCardModal from 'components/common/modals/OverviewCardModal';
import MemberModal from 'components/Organizations/SingleOrganization/Groups/Member/MemberModal';

const LineChartContainer = ({
  title,
  viewMoreButton,
  viewMoreRedirect,
  viewKey,
  viewKey1,
  view,
  memberInfo,
  interestRate,
  date,
  amount,
  data,
  background,
  dataKey,
  color,
  style,
  noLineCharts,
  onViewMoreClicked,
}) => {
  const { push } = useHistory();
  const {
    tab,
    organizationId,
    tabOption,
    projectId,
    option,
  } = useParams();
  const backgroundColor = Color(background);
  const lineChartContainerRef = useRef();
  const [lineChartWidth, setLineChartWidth] = useState(0);
  const [popup, setPopup] = useState(false);
  const [memberModal, setMemberModal] = useState(false);
  const [content, setContent] = useState({
    action: '',
    title: '',
    header: '',
  });
  const totalSizePaddings = 40;

  const agentId =
    tab === 'agents' && !projectId
      ? tabOption
      : tab === 'agents' && projectId
      ? option
      : null;
  const groupId =
    tab === 'groups' && !projectId
      ? tabOption
      : tab === 'groups' && projectId
      ? option
      : null;

  useEffect(() => {
    if (lineChartContainerRef.current)
      setLineChartWidth(
        lineChartContainerRef.current.clientWidth - totalSizePaddings,
      );

    window.addEventListener('resize', () => {
      if (lineChartContainerRef.current) {
        setLineChartWidth(
          lineChartContainerRef.current.clientWidth -
            totalSizePaddings,
        );
      }
    });
  }, [lineChartContainerRef]);

  const viewMore = (url, query) => (
    <span
      className="line-chart__icon"
      onClick={() =>
        onViewMoreClicked
          ? onViewMoreClicked()
          : url && query
          ? push(formatUrlParameter(url, query))
          : url
          ? push(url)
          : null
      }
    >
      <span className="line-chart__icon--view">View more</span>
      <img
        src={chevronArrowRight}
        alt=""
        className="line-chart__icon--arrow"
      />
    </span>
  );

  const openPopup = () => {
    setPopup(true);
    setContent({
      title,
      action: viewKey,
      header: `${title} (${amount})`,
      organizationId,
    });
  };

  return (
    <>
      <OverviewCardModal
        popup={popup}
        setPopup={setPopup}
        content={content}
        organizationId
      />
      <MemberModal
        memberModal={memberModal}
        setMemberModal={setMemberModal}
        memberInfo={memberInfo}
        interestRate={interestRate}
      />

      <div
        className="line-chart__container"
        style={{ ...style, backgroundColor: background }}
      >
        <div className="line-chart__heading">
          <h1 className="line-chart__title">
            <span className="line-chart__title__thetruetitle">
              {title}
            </span>
            {viewMoreButton && (
              <Icon
                name="ellipsis horizontal"
                fitted
                className="pointer"
                onClick={
                  view === 'member'
                    ? () => setMemberModal(true)
                    : openPopup
                }
              />
            )}
            {viewMoreRedirect && view === 'member'
              ? viewMore(`${memberInfo?.user_id}/transactions`, {
                  purpose: viewKey,
                })
              : viewMoreRedirect && view === 'general' && viewKey
              ? viewMore(`/${viewKey1}`, {
                  tab: viewKey,
                })
              : viewMoreRedirect && view === 'general'
              ? viewMore(`/${viewKey1}`)
              : view === 'organization'
              ? viewMore(`${organizationId}/${viewKey1}`)
              : view === 'profiles'
              ? viewMore(`${viewKey1}`)
              : viewMoreRedirect && view === 'agent' && !projectId
              ? viewMore(
                  `/organizations/${organizationId}/agents/${agentId}/groups`,
                )
              : viewMoreRedirect && view === 'agent' && projectId
              ? viewMore(
                  `/projects/${projectId}/organizations/${organizationId}/agents/${agentId}/groups`,
                )
              : viewMoreRedirect && view === 'groups'
              ? viewMore(`${groupId}/activities`, {
                  category: viewKey1,
                  type: viewKey,
                })
              : view === 'partners'
              ? viewMore(
                  `/projects/${projectId}/organizations/${organizationId}/${viewKey1}`,
                )
              : ''}
          </h1>

          <span className="line-chart__date">{date}</span>
        </div>
        <Divider />
        <div className="line-chart__amount">
          <h3>{amount}</h3>
        </div>
        {!noLineCharts && (
          <div
            ref={lineChartContainerRef}
            className="line-chart__graph"
            style={{ backgroundColor: backgroundColor.darken(0.2) }}
          >
            <LineChart
              data={data}
              color={color}
              dataKey={dataKey}
              width={lineChartWidth}
            />
          </div>
        )}
      </div>
    </>
  );
};

LineChartContainer.propTypes = {
  title: PropTypes.string.isRequired,
  viewMoreButton: PropTypes.bool,
  viewMoreRedirect: PropTypes.bool,
  viewKey: PropTypes.string,
  view: PropTypes.string,
  date: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Array),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  background: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  color: PropTypes.string,
  style: PropTypes.objectOf(Object),
  interestRate: PropTypes.number,
  noLineCharts: PropTypes.bool,
  memberInfo: PropTypes.objectOf(Object),
  viewKey1: PropTypes.string,
  onViewMoreClicked: PropTypes.func,
};

LineChartContainer.defaultProps = {
  color: '#FFFFFF',
  style: {},
  data: [],
  amount: '',
  viewMoreButton: false,
  viewMoreRedirect: false,
  viewKey: '',
  view: '',
  interestRate: 0,
  noLineCharts: false,
  memberInfo: {},
  viewKey1: '',
  onViewMoreClicked: undefined,
};

export default LineChartContainer;
